.Toastify {
  display: flex;
  justify-content: center;
}

.MIToastContainer {
  margin: 0;
  padding: 0;
  width: auto;
  top: 3.5rem;
  z-index: 11000;
}

.MIToastInner {
  margin: 0 0 2rem 0;
  padding: 0;
  border-radius: 0;
  box-shadow: 0 5px 10px 0 rgba(33, 33, 36, 0.2);
  min-height: auto;
  border-radius: 0.8rem;
}

.Toastify__toast-icon {
  display: none;
}

.Toastify__toast-body {
  margin: 0;
  padding: 0;
}

.Toastify__toast {
  font-family: Poppins;
}
