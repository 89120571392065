/* poppins-regular - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/Poppins/poppins-v5-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Poppins Regular'), local('Poppins-Regular'),
  url('../fonts/Poppins/poppins-v5-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/Poppins/poppins-v5-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/Poppins/poppins-v5-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/Poppins/poppins-v5-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/Poppins/poppins-v5-latin-regular.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-600 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../fonts/Poppins/poppins-v5-latin-600.eot'); /* IE9 Compat Modes */
  src: local('Poppins SemiBold'), local('Poppins-SemiBold'),
  url('../fonts/Poppins/poppins-v5-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/Poppins/poppins-v5-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/Poppins/poppins-v5-latin-600.woff') format('woff'), /* Modern Browsers */
  url('../fonts/Poppins/poppins-v5-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/Poppins/poppins-v5-latin-600.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-700 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../fonts/Poppins/poppins-v5-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Poppins Bold'), local('Poppins-Bold'),
  url('../fonts/Poppins/poppins-v5-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/Poppins/poppins-v5-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/Poppins/poppins-v5-latin-700.woff') format('woff'), /* Modern Browsers */
  url('../fonts/Poppins/poppins-v5-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/Poppins/poppins-v5-latin-700.svg#Poppins') format('svg'); /* Legacy iOS */
}
@font-face {
  font-family: 'Avenir Next forINTUIT';
  src: url('../fonts/Avenir/avenir-700.woff2') format('woff2');
  font-weight: 700;
}
@font-face {
  font-family: 'Avenir Next forINTUIT';
  src: url('../fonts/Avenir/avenir-600.woff2') format('woff2');
  font-weight: 600;
}
@font-face {
  font-family: 'Avenir Next forINTUIT';
  src: url('../fonts/Avenir/avenir-500.woff2') format('woff2');
  font-weight: 500;
}
@font-face {
  font-family: 'Avenir Next forINTUIT';
  src: url('../fonts/Avenir/avenir-400.woff2') format('woff2');
  font-weight: 400;
}

@font-face {
  font-family: 'Melio';
  font-display: swap;
  font-weight: normal;
  font-style: normal;
  src:
    url('../fonts/Melio/Melio.eot') format('embedded-opentype'),
    url('../fonts/Melio/Melio.woff') format('woff'),
    url('../fonts/Melio/Melio.ttf') format('truetype'),
    url('../fonts/Melio/Melio.svg') format('svg');
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Melio' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
